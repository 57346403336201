<template>
  <div>
    <button class="btn btn-outline-blue mb-3" @click="addPhone">
      <icon type="plus" /> Add Phone
    </button>
    <alert class="my-4" v-if="!user" />
    <alert
      class="my-4"
      v-if="user && user.phones && user.phones.length == 0"
      :hideLoader="true"
      >No Phones</alert
    >
    <div
      class="card mobile-tablet-no-card table-container border-0"
      v-if="user && user.phones && user.phones.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Number</th>
            <th>Status</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="phone in user.phones" :key="phone.id">
            <td>
              <b class="mobile-tablet-only mr-2">Number:</b>{{ phone.number }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b
              ><span
                :class="
                  phone.status == 'ACTIVE' ? 'text-success' : 'text-danger'
                "
                >{{ phone.status }}</span
              >
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Type:</b>{{ phone.type }}
            </td>
            <td class="text-right td-actions">
              <button
                class="btn btn-danger btn-sm"
                @click="deletePhone(phone)"
                :disabled="phone.isDeleting"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <icon type="trash" v-if="!phone.isDeleting" />
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="phone.isDeleting"
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AdminPhone",
  computed: {
    ...mapState({
      user: (state) => state.Profile.user,
    }),
  },
  methods: {
    ...mapActions({
      remove: "Profile/removePhone",
    }),
    addPhone: function () {
      this.$router.push({
        name: "admin.profile.phones.create",
        query: { src: "phones" },
      });
    },
    deletePhone: function (phone) {
      phone.isDeleting = true;
      let vm = this;
      vm.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this phone number?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          vm.remove({
            user: vm.user.id,
            id: phone.id,
          });
        } else {
          phone.isDeleting = false;
        }
      });
    },
  },
};
</script>
